import * as Sentry from '@sentry/react';

function errorFromProgressEvent(event) {
  const err = new Error('ProgressEvent captured as exception', {
    cause: event,
  });
  err.progressEvent = {
    lengthComputable: event.lengthComputable,
    loaded: event.loaded,
    total: event.total,
  };
  return err;
}

/**
 * Sends an error to Sentry with additional information.
 *
 * @param {Error} err - The error object to be sent to Sentry.
 * @param {Object} extra - Additional information related to the error.
 *   It can be any object providing additional context about the error.
 */
export function sendErrWithExtra(err, extra) {
  Sentry.setExtra('extra', extra);
  Sentry.captureException(err);
}

export default function sendSentryError(err, msgError) {
  if (!err) return;
  if (err.response && err.response.status >= 400 && err.response.status < 500) {
    return;
  }
  let sentryErr;
  if (err instanceof ProgressEvent) {
    sentryErr = errorFromProgressEvent(err);
  } else {
    sentryErr = err;
  }
  console.warn(`${msgError} : ${sentryErr}`);
  sentryErr.message = `${sentryErr.message}${
    sentryErr.message ? '. ' : ''
  }${msgError}`;
  Sentry.captureException(sentryErr);
}
