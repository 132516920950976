/* eslint-disable max-classes-per-file */
export class HttpError extends Error {
  constructor(status, response, request, responseBody = null) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(`status=${status}`);
    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, HttpError);
    }
    this.name = 'HttpError';
    // Custom debugging information
    this.status = status;
    this.response = response;
    this.request = request;

    let responseBodyParsed = null;
    try {
      responseBodyParsed = JSON.parse(responseBody);
    } catch (err) {
      console.warn(`Error parsing responseBody on HttpError ${err}`);
    }

    /* Request and Response objects are not serialized into sentry. */
    this.requestJson = {
      url: request.url,
      method: request.method,
    };
    this.responseJson = {
      status: response.status,
      statusText: response.statusText,
      redirected: response.redirected,
      bodyText: responseBody,
      bodyJson: responseBodyParsed,
      url: response.url,
      headers: Object.fromEntries(response.headers),
    };
  }
}

export class ConnectionError extends Error {
  constructor(...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ConnectionError);
    }

    this.name = 'ConnectionError';
  }
}
