import { GrowthBook } from '@growthbook/growthbook-react';

interface ExperimentEvent {
  attributeId: string;
  event: string;
  experimentId: string;
  variationId: string;
}

// to fix "gbClientApurata doesn't exists in type Window & typeof global"
// we have to extend the global window interface and we can extend it like this
declare global {
  interface Window {
    gbClientApurata?: {
      isOn: (experimentName: string) => boolean;
    };
    dataLayer: ExperimentEvent[];
  }
}

export default function getOrCreateGrowthbookClient(sessionId) {
  if (!window.gbClientApurata) {
    const attributeId = sessionId;
    if (!attributeId) console.error('GrowthbookClient with session null');
    window.gbClientApurata = new GrowthBook({
      apiHost: 'https://cdn.growthbook.io',
      clientKey: import.meta.env.REACT_APP_GROWTHBOOK_CLIENT_KEY,
      // Enable easier debugging during development
      enableDevMode: import.meta.env.REACT_APP_ENV === 'local',
      // Update the instance in realtime as features change in GrowthBook
      subscribeToChanges: import.meta.env.REACT_APP_ENV === 'local',
      attributes: {
        id: attributeId,
      },
      // Only required for A/B testing
      // Called every time a user is put into an experiment
      trackingCallback: (experiment, result) => {
        window.dataLayer.push({
          attributeId,
          event: 'experiment_viewed',
          experimentId: experiment.key,
          variationId: result.key,
        });
      },
    });
  }
  return window.gbClientApurata;
}
