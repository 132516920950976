import React, {
  Suspense,
  lazy,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import {
  BrowserRouter, Switch, Redirect, Route,
} from 'react-router-dom';
import {
  CompatRouter,
  useParams,
} from 'react-router-dom-v5-compat';
import * as Sentry from '@sentry/react';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import LoadingSpinner from './components/SpinnerOverlay';
import ErrorFallback from './components/BoundaryError';
// Bootstrap is used across the whole project, thus we load it before anything else
import 'bootstrap/dist/css/bootstrap.min.css'; // +145kb
// Toastify is used across the whole project, thus we load it before anything else
import 'react-toastify/dist/ReactToastify.css'; // +10kb
import { SaveSessionIdLocalStorage } from './services/SessionCookie';
import getOrCreateGrowthbookClient from './services/GbClient.ts';
import GlobalContext from './GlobalContext';
import { ApurataRoute } from './routes';

// Need to go before lazy() calls
const renderLoader = () => <p>Cargando...</p>;

/* eslint-disable import/no-cycle */
const ValidatingApurata = lazy(() => import('./screens/Funnel/ValidatingApurata/ValidatingApurata.tsx'));
const Funded = lazy(() => import('./screens/Funnel/FundedApurata/Funded.tsx'));
const CameraValidation = lazy(() => import('./screens/CameraValidation'));
const ScreenDownPayment = lazy(() => import('./screens/Downpayment/ScreenDownPayment'));
const ScreenTestPrd = lazy(() => import('./screens/Downpayment/ScreenTestPrd'));
const ApplicationStatus = lazy(() => import('./screens/Funnel/ApplicationStatus/ApplicationStatus.tsx'));
// const Home = lazy(() => import('./screens/Home'));
const HomePeople = lazy(() => import('./screens/HomePeople/HomePeople'));
const Integrations = lazy(() => import('./screens/Integrations'));
const LandingMerchant = lazy(() => import('./screens/LandingMerchant'));
const LandingStores = lazy(() => import('./screens/LandingStores/LandingMarketplace'));
const LandingPartner = lazy(() => import('./screens/LandingPartner/LandingPartner'));
const MercadoPago = lazy(() => import('./screens/Versus/MercadoPago'));
const NotFound = lazy(() => import('./screens/NotFound'));
const PhoneEmailIdVerification = lazy(() => import('./screens/VerificationsPhoneEmailID/PhoneEmailIdVerification.tsx'));
const RedirectCountdown = lazy(() => import('./screens/RedirectCountdown'));
const ValidatingPhotoAndPayment = lazy(() => import('./screens/ValidatingPhotoAndPayment'));
const ValidatingTransaction = lazy(() => import('./screens/ValidatingTransaction'));
const FundingCodePos = lazy(() => import('./screens/FundingCodePos'));
const SuccessPurchase = lazy(() => import('./screens/SuccessPurchase'));
const Plugin = lazy(() => import('./screens/Plugin'));
const PaymentInstructions = lazy(() => import('./screens/Paylink/PaymentInstructions'));
const RegisterDownpaymentVoucher = lazy(() => import('./screens/Paylink/RegisterPayment'));
const Landing = lazy(() => import('./screens/Paylink/Landing'));
const Dashboard = lazy(() => import('./screens/Paylink/Dashboard'));
const DashboardList = lazy(() => import('./screens/Paylink/DashboardList'));
const ProfileForm = lazy(() => import('./screens/Paylink/ProfileForm/ProfileForm.tsx'));
const PaymentFunnel = lazy(() => import('./screens/Funnel/RegisterPayment/RegisterPayment.tsx'));
const StepOne = lazy(() => import('./screens/StepOne/StepOne'));
const DontCreateLoans = lazy(() => import('./screens/FunnelErrors/DontCreateLoans'));
const DontFinanceLoan = lazy(() => import('./screens/FunnelErrors/DontFinanceLoan'));
const FailCancelLend = lazy(() => import('./screens/FunnelErrors/FailCancelLend'));
const NudgeAcuotaz = lazy(() => import('./screens/NudgeAcuotaz/NudgeAcuotaz'));
const LayoutPaylink = lazy(() => import('./screens/Paylink/LayoutPaylink'));
const DownpaymentCard = lazy(() => import('./screens/DownpaymentCard'));
const DownpaymentTransfer = lazy(() => import('./screens/DownpaymentTransfer/index'));
const DownpaymentYape = lazy(() => import('./screens/DownpaymentYape/DownPaymentYape'));
const DownpaymentFullLoan = lazy(() => import('./screens/DownpaymentFullLoan/DownpaymentFullLoan'));
const ResultsValidationDownpayment = lazy(() => import('./screens/DownpaymentFullLoan/ResultsValidationDownpayment'));
const PhotocheckScreen = lazy(() => import('./screens/Photocheck/Photocheck.tsx'));
const ScreenSimulatorAcuotaz = lazy(() => import('./screens/Simulators/ScreenSimulatorAcuotaz'));
const ScreenSimulatorPrecal = lazy(() => import('./screens/Simulators/ScreenSimulatorPrecal'));
const PrecalEval = lazy(() => import('./screens/Funnel/precal/PrecalEval'));
const PrecalApproved = lazy(() => import('./screens/Funnel/precal/PrecalApproved'));
const PrecalRejected = lazy(() => import('./screens/Funnel/precal/PrecalRejected'));
const PrecalIncompleted = lazy(() => import('./screens/Funnel/precal/PrecalIncompleted'));
const ExternalDownPaymentInstructions = lazy(() => import('./screens/ExternalDownPaymentInstructions'));
const ScreenExtendedLine = lazy(() => import('./screens/Funnel/ScreenExtendedLine'));
const VouchersTableTest = lazy(() => import('./screens/Funnel/VoucherTableTest'));
const ApplyDenied = lazy(() => import('./screens/Funnel/ApplyDenied/ApplyDenied.tsx'));
/* eslint-enable import/no-cycle */

// Call it once in your app. At the root of your app is the best place
toast.configure();

function Paylink(props) {
  const { match } = props;
  return (
    <div className="paylink-screens">
      <Suspense fallback={renderLoader()}>
        <LayoutPaylink>
          <Switch>
            <ApurataRoute path={`${match.url}/`} exact component={Landing} />
            <ApurataRoute path={`${match.url}/dashboard/:paylinkId`} exact component={Dashboard} auth />
            <ApurataRoute path={`${match.url}/dashboard`} exact component={DashboardList} auth />
            <ApurataRoute path={`${match.url}/profile/:paylink_id`} exact component={ProfileForm} auth />
          </Switch>
        </LayoutPaylink>
      </Suspense>
    </div>
  );
}

Paylink.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
};

function PosOrder(props) {
  const { match } = props;
  return (
    <div className="paylink-screens">
      <Suspense fallback={renderLoader()}>
        <LayoutPaylink>
          <Switch>
            <ApurataRoute path={`${match.url}/dashboard/:posClientId`} exact component={Dashboard} auth />
          </Switch>
        </LayoutPaylink>
      </Suspense>
    </div>
  );
}
PosOrder.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
};

function RedirectCotizadorAcuotaz() {
  const params = useParams();

  const plkPattern = /(plk|paylink)/i;
  if (plkPattern.test(params.client_id)) {
    return <Redirect to={`/cotizador-acuotaz/plk/${params.client_id}`} />;
  }
  return <Redirect to={`/cotizador-acuotaz/pos/${params.client_id}`} />;
}

function RedirectTotiendas() {
  return <Redirect to="/tiendas" />;
}

function runOnce(fn, context) {
  // https://stackoverflow.com/a/12713611
  let result;
  let fnCopy = fn;
  return (...rest) => {
    if (fnCopy) {
      result = fnCopy.apply(context || this, rest);
      fnCopy = null;
    }
    return result;
  };
}

const setUnhandledRejectionEventListenerOnce = runOnce((listener) => window.addEventListener('unhandledrejection', listener));

function App() {
  const [error, setError] = useState(null);
  const [gb, setGb] = useState(null);
  const [loading, setLoading] = useState(true);

  // Code in this scope can run more than once
  window.acuotazAppSetError = setError;

  useEffect(() => {
    // This useEffect() should alwas call setGb() before finishing
    async function setSessionAndCreateGbClient() {
      setLoading(true);
      try {
        const sessionId = await SaveSessionIdLocalStorage();
        setGb(getOrCreateGrowthbookClient(sessionId));
      } catch (err) {
        setGb(getOrCreateGrowthbookClient(null));
      }
    }
    setSessionAndCreateGbClient();
  }, []);

  useEffect(() => {
    if (gb) {
      setLoading(false);
      // Load features from the GrowthBook API and initialize the SDK
      gb.loadFeatures();
    }
  }, [gb]);

  setUnhandledRejectionEventListenerOnce((event) => {
    /*
      El propósito de esta función es bloquear toda la interfaz con un error si es que hay un error sin manejar en una promesa.
      La idea es hacer explícitas las promesas en las que no se maneja el error, lo más común son los fetch.
      En el caso de un fetch de google ads bloqueado por el ad-blocker, el evento no tiene suficiente información para poderlo diferenciar de un fetch a apurata.com,
      por esto es que lo estamos deshabilitando en producción, para no bloquear el funnel a los clientes que tienen ad-blocker.
    */
    if (import.meta.env.REACT_APP_API_SERVER.includes('localhost')) {
      const eventReason = event.reason;
      eventReason.message = `Localhost only: ${eventReason.message}`;
      window.acuotazAppSetError(eventReason); // Cannot use setError directly because it changes on every run of App()
    } else {
      console.warn('UNHANDLED PROMISE REJECTION', event);
    }
  });

  if (loading) {
    return <LoadingSpinner show text="Cargando ..." />;
  }

  return (
    <GrowthBookProvider growthbook={gb}>
      <BrowserRouter basename="/app">
        <CompatRouter>
          <div className="App">
            <Suspense fallback={renderLoader()}>
              {/*
                using useMemo It is very laborious since it covers many components
                and in addition to testing that they work, we must test that the
                error is handled appropriately, that's the reason why need disable
                the rule below
              */}
              {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
              <GlobalContext.Provider value={{ error }}>
                <Sentry.ErrorBoundary fallback={ErrorFallback}>
                  <Switch>
                    <ApurataRoute exact path="/linea-extendida/:order_id" component={ScreenExtendedLine} />
                    <ApurataRoute exact path="/validar-identidad" component={CameraValidation} checkUrl />
                    <ApurataRoute exact path="/apurata/validar-identidad" component={CameraValidation} checkUrl />
                    <ApurataRoute
                      exact
                      path="/verificar-email-fono-id"
                      component={PhoneEmailIdVerification}
                      checkUrl
                      redirectOnNonActiveLoan
                    />
                    <ApurataRoute
                      exact
                      path="/apurata/verificar-email-fono-id"
                      component={PhoneEmailIdVerification}
                      checkUrl
                      redirectOnNonActiveLoan
                    />
                    <ApurataRoute
                      exact
                      path="/validando-informacion"
                      component={ValidatingPhotoAndPayment}
                      checkUrl
                      setIntervalTime={10000}
                    />
                    <ApurataRoute
                      exact
                      path="/validando-pago"
                      component={ValidatingTransaction}
                      checkUrl
                      setIntervalTime={10000}
                    />
                    <ApurataRoute exact path="/redirigir-a-tienda" component={RedirectCountdown} checkUrl />
                    <ApurataRoute exact path="/test-prd" component={ScreenTestPrd} />
                    <ApurataRoute exact path="/completar-pago" component={ScreenDownPayment} checkUrl />
                    <ApurataRoute exact path="/completar-pago-con-tarjeta" component={DownpaymentCard} checkUrl />
                    <ApurataRoute exact path="/completar-pago-con-yape" component={DownpaymentYape} />
                    <ApurataRoute exact path="/completar-pago-por-transferencia" component={DownpaymentTransfer} checkUrl />
                    <ApurataRoute exact path="/cotizador-acuotaz/:client_id" component={RedirectCotizadorAcuotaz} />
                    <ApurataRoute exact path="/cotizador-acuotaz/plk/:client_id" component={ScreenSimulatorAcuotaz} />
                    <ApurataRoute exact path="/cotizador-acuotaz/pos/:client_id" component={ScreenSimulatorAcuotaz} />
                    {/* Used to display the Monthly simulator to client_ids that have 2free installments */}
                    <ApurataRoute exact path="/precal/:client_id" component={ScreenSimulatorPrecal} />
                    <ApurataRoute exact path="/precal-eval" component={PrecalEval} />
                    <ApurataRoute exact path="/precal-calificado" component={PrecalApproved} />
                    <ApurataRoute exact path="/precal-denegado" component={PrecalRejected} />
                    <ApurataRoute exact path="/precal-incompleto" component={PrecalIncompleted} />
                    <ApurataRoute
                      exact
                      path="/aplicar/paso-uno"
                      component={StepOne}
                      checkUrl
                      redirectOnNonActiveLoan
                    />
                    <ApurataRoute
                      exact
                      path="/apurata/aplicar/paso-uno"
                      component={StepOne}
                      checkUrl
                      redirectOnNonActiveLoan
                    />
                    <ApurataRoute
                      exact
                      path="/aplicar/denegado"
                      component={ApplyDenied}
                      checkUrl
                      redirectOnNonActiveLoan
                    />
                    <ApurataRoute
                      exact
                      path="/pos/aplicar/denegado"
                      component={ApplyDenied}
                      checkUrl
                      redirectOnNonActiveLoan
                    />
                    <ApurataRoute
                      exact
                      path="/deposito-listo"
                      component={Funded}
                      checkUrl
                      redirectOnNonActiveLoan
                    />
                    <ApurataRoute
                      exact
                      path="/validando"
                      component={ValidatingApurata}
                      checkUrl
                      redirectOnNonActiveLoan
                      setIntervalTime={20000}
                    />
                    <ApurataRoute exact path="/not-authorized-create-loans" component={DontCreateLoans} />
                    <ApurataRoute exact path="/we-cant-finance-you" component={DontFinanceLoan} />
                    <ApurataRoute exact path="/fail-cancel-lend" component={FailCancelLend} />
                    <ApurataRoute exact path="/acuotaz/:name" component={NudgeAcuotaz} />
                    <ApurataRoute
                      exact
                      path="/estado-aplicacion"
                      component={ApplicationStatus}
                      checkUrl
                      redirectOnNonActiveLoan
                    />
                    <ApurataRoute
                      exact
                      path="/apurata/estado-aplicacion"
                      component={ApplicationStatus}
                      checkUrl
                      redirectOnNonActiveLoan
                    />
                    <ApurataRoute exact path="/integraciones" component={Integrations} />
                    <ApurataRoute exact path="/cuotas_apurata_vs_mercado_pago" component={MercadoPago} />
                    <ApurataRoute exact path="/plugin/:name" component={Plugin} />
                    <ApurataRoute exact path="/para-clientes" component={HomePeople} />
                    <ApurataRoute exact path="/payment-instructions/:order_id" component={PaymentInstructions} />
                    <ApurataRoute
                      exact
                      path="/funding-code-pos"
                      component={FundingCodePos}
                      auth
                      checkUrl
                      setIntervalTime={10000}
                      redirectOnNonActiveLoan
                    />
                    <ApurataRoute
                      exact
                      path="/compra-exitosa"
                      component={SuccessPurchase}
                      auth
                      checkUrl
                      redirectOnNonActiveLoan
                    />

                    {/* flow after waiting-payment conflict to pay full lend */}
                    <ApurataRoute
                      exact
                      path="/full-pmt-flow/pagar-credito-actual-total"
                      component={DownpaymentFullLoan}
                      checkUrl
                    />
                    <ApurataRoute
                      exact
                      path="/full-pmt-flow/registrar-pago"
                      component={PaymentFunnel}
                      checkUrl
                    />
                    <ApurataRoute
                      exact
                      path="/full-pmt-flow/registrar-pago-corregido"
                      component={PaymentFunnel}
                      checkUrl
                    />
                    <ApurataRoute
                      exact
                      path="/test-voucher-ocr-js"
                      component={VouchersTableTest}
                      checkUrl
                    />
                    <ApurataRoute
                      exact
                      path="/full-pmt-flow/validando-pago"
                      component={ResultsValidationDownpayment}
                      checkUrl
                      setIntervalTime={10000}
                    />
                    <ApurataRoute
                      exact
                      path="/full-pmt-flow/pago-validado-exitosamente"
                      component={ResultsValidationDownpayment}
                      checkUrl
                    />
                    <ApurataRoute
                      exact
                      path="/full-pmt-flow/pago-invalidado"
                      component={ResultsValidationDownpayment}
                      checkUrl
                    />
                    <ApurataRoute
                      exact
                      path="/full-pmt-flow/pago-monto-menor-al-monto-total"
                      component={ResultsValidationDownpayment}
                      checkUrl
                    />
                    <ApurataRoute
                      exact
                      path="/full-pmt-flow/validacion-pago-tardada"
                      component={ResultsValidationDownpayment}
                      checkUrl
                    />
                    <ApurataRoute
                      exact
                      path="/fotocheck/:personKey"
                      component={PhotocheckScreen}
                    />

                    <ApurataRoute exact path="/register-payment" component={RegisterDownpaymentVoucher} checkUrl />
                    <ApurataRoute exact path="/partners/:name" component={LandingPartner} />
                    {/* paylink route should be just Route because renders a Switch of ApurataRoutes,
                        avoiding to call ApurataRoute hooks twice */}
                    <Route path="/paylink" component={Paylink} />
                    <Route path="/pos" component={PosOrder} />
                    <ApurataRoute exact path="/registrar-pago/nuevo-pago" component={PaymentFunnel} />
                    <ApurataRoute exact path="/tiendas-comprar-en-cuotas" component={RedirectTotiendas} />
                    <ApurataRoute exact path="/tiendas" component={LandingStores} />
                    <ApurataRoute
                      exact
                      path="/instrucciones-para-pagar-la-inicial-externamente"
                      component={ExternalDownPaymentInstructions}
                      checkUrl
                      setIntervalTime={10000}
                      redirectOnNonActiveLoan
                    />
                    <ApurataRoute exact path="/" component={LandingMerchant} />
                    <ApurataRoute component={NotFound} />
                  </Switch>
                </Sentry.ErrorBoundary>
              </GlobalContext.Provider>
            </Suspense>
          </div>
        </CompatRouter>
      </BrowserRouter>
    </GrowthBookProvider>
  );
}

export default App;
